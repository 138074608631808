import * as React from 'react';

export const HOProUserImageBackground = (props: any) => (
	<svg
		width={45}
		height={16}
		viewBox='0 0 45 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			id='Ellipse 195'
			d='M44.74 10.7707C42.7291 7.60243 39.4883 4.94471 35.4275 3.1336C31.3667 1.3225 26.6682 0.439347 21.9262 0.595834C17.1842 0.75232 12.6117 1.94141 8.78685 4.01275C4.96201 6.08408 2.05665 8.94462 0.438176 12.2326L23 18.2623L44.74 10.7707Z'
			fill='url(#paint0_linear_7130_27187)'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_7130_27187'
				x1={1.21427}
				y1={9.07573}
				x2={48.3854}
				y2={20.1389}
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#CE67FF' />
				<stop offset={1} stopColor='#FB43FF' />
			</linearGradient>
		</defs>
	</svg>
);
