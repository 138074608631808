import React from 'react';
import styled from 'styled-components';

import { useMembershipProfileViewed } from 'Hooks/useMembershipProfileViewed';

import colors from 'Static/typography/colors';

import Conditional from './conditional';

const RedDotWrapper = styled.div<{ isAccountsFooter?: boolean }>`
	height: 10px;
	width: 10px;
	background-color: ${colors.WARNING_RED};
	border-radius: 50%;
	position: absolute;
	top: 0;
	right: -1px;
	border: 2px solid ${colors.WHITE};
	${({ isAccountsFooter }) =>
		isAccountsFooter ? `right: -5px` : `right: -1px`};
`;

interface IHeadoutMembershipRedDot {
	isAccountsFooter?: boolean;
}

export const HeadoutMembershipRedDot: React.FC<
	React.PropsWithChildren<IHeadoutMembershipRedDot>
> = props => {
	const { isAccountsFooter = false } = props;
	const { isProfileViewed } = useMembershipProfileViewed();

	return (
		<Conditional if={!isProfileViewed}>
			<RedDotWrapper isAccountsFooter={isAccountsFooter} />
		</Conditional>
	);
};
