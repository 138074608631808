import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import withCookies from 'HOC/withCookies';

import HeaderUserBox from 'Components/common/headerUserBox';
import LoadingDots from 'Components/common/loadingDots';

import { trackEvent } from 'Utils/analytics';
import { getCurrentPage, getUser, getUserFetchStatus } from 'Utils/stateUtils';

import { fetchUserDetails, signOut } from 'Thunks/user';
import { openLoginModal } from 'Actions/user';

import { ANALYTICS_EVENTS } from 'Constants/analytics';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const SecondaryButtonCSS = css`
	height: 2.5rem;
	padding: 0.625rem 0.75rem;
	border: 1px solid ${colors.GREY_DS.G2};
	color: ${colors.GREY_DS.G2};
	.login-text-span {
		${getFontDetailsByLabel(TYPE_LABELS.BUTTON_SMALL)};
	}
	&:hover {
		background: ${colors.PURPS};
		border: 1px solid ${colors.PURPS};
		span {
			color: ${colors.WHITE};
		}
	}
`;
const TertiaryButtonCSS = css`
	height: 2.5rem;
	padding: 0.5rem 0.75rem;
	color: ${colors.WHITE};
	border: 1px solid ${colors.WHITE};
	.login-text-span {
		${getFontDetailsByLabel(TYPE_LABELS.BUTTON_SMALL)};
	}
	&:hover {
		background: ${colors.PURPS};
		border-color: ${colors.PURPS};
	}
`;

const HeaderLoginBoxWrapper = styled.div`
	position: relative;
	margin-left: 0.75rem;
	display: flex;
	align-items: center;

	&:focus-visible {
		outline: 0.125rem solid ${colors.BRAND_COLORS.PURPS};
		border-radius: 0.25rem;
	}

	.login-text {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0.25rem;
		background-color: ${colors.TRANSPARENT};

		${({
			// @ts-expect-error TS(2339): Property 'tertiaryButton' does not exist on type '... Remove this comment to see the full error message
			tertiaryButton,
		}) => (tertiaryButton ? TertiaryButtonCSS : SecondaryButtonCSS)}

		svg {
			width: 0.625rem;
			height: 0.625rem;
			margin-left: 0.625rem;
			fill: ${colors.PURPS};
			vertical-align: middle;
			margin-top: 0.125rem;

			&.default-user-icon {
				width: 1.5625rem;
				height: 1.5625rem;
				margin-left: 0;
				margin-top: 0;
			}
		}
	}

	.login-popup {
		position: absolute;
		width: 18.75rem;
		min-height: 6.25rem;
		top: 2.375rem;
		right: 0;
		box-shadow: 0 -1px 12px rgba(0, 0, 0, 0.1);
		transition: all 0.2s ease-in-out 0s;
		max-height: 0;
		overflow: hidden;
		z-index: 10;
		background-color: ${colors.WHITE};
		opacity: 0;
		pointer-events: none;
		padding: 25px;

		&.hovered {
			z-index: 9999;
			top: 2.375rem;
			max-height: 31.25rem;
			opacity: 1;
			pointer-events: auto;
		}

		.login-button {
			padding: 0.9375rem 0.625rem;
			margin-bottom: 1.25rem;
		}

		.divider {
			margin: 0 1.25rem;
			border: 0;
			border-bottom: 1px solid ${colors.GREY.E7};
		}

		.login-pitch {
			margin-top: 1.25rem;

			.login-pitch-header {
				span {
					font-weight: 900;
					line-height: 0.71;
					font-size: 0.875rem;
				}
			}

			.login-pitch-list {
				.login-pitch-item-wrapper {
					margin-top: 1.25rem;
					display: flex;
					align-items: flex-start;
					white-space: normal;

					svg {
						width: 1.875rem;
						height: 1.875rem;
						margin-top: 0.125rem;
						flex-shrink: 0;
					}

					.login-pitch-item {
						margin-left: 1.25rem;

						span {
							font-size: 0.75rem;
							font-weight: 300;
						}
					}
				}
			}
		}
	}
`;

type HeaderLoginProps = {
	currentPage: string;
	user?: any;
	onSignOut?: (...args: any[]) => any;
	checkoutFlow?: boolean;
	onFetchUserDetails?: (...args: any[]) => any;
	isFetchingUser?: boolean;
	cookies?: any;
	openModal?: (...args: any[]) => any;
	isUserInfoLoading?: boolean;
	tertiaryButton?: any;
};

const HeaderLogin = ({
	onFetchUserDetails,
	cookies,
	onSignOut,
	user,
	currentPage,
	isUserInfoLoading,
	openModal,
	checkoutFlow,
	tertiaryButton,
}: HeaderLoginProps) => {
	React.useEffect(() => {
		// @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
		onFetchUserDetails({ cookies });
	}, []);

	const handleSignInButtonClick = () => {
		trackEvent({
			eventName: ANALYTICS_EVENTS.SIGN_IN_STEP_1,
		});
		// @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
		openModal();
	};
	if (user) {
		return (
			<HeaderUserBox
				// @ts-expect-error TS(2322): Type '{ user: any; onSignOut: () => any... Remove this comment to see the full error message
				user={user}
				// @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
				onSignOut={() => onSignOut({ cookies })}
				currentPage={currentPage}
				checkoutFlow={checkoutFlow}
			/>
		);
	}

	return !isUserInfoLoading ? (
		<HeaderLoginBoxWrapper
			// @ts-expect-error TS(2769): No overload matches this call.
			tertiaryButton={tertiaryButton}
			className='header-login-box-wrapper'
		>
			<button
				aria-label={strings.CMN_SIGN_IN}
				className='login-text'
				onClick={handleSignInButtonClick}
			>
				<span className='login-text-span'>{strings.CMN_SIGN_IN}</span>
			</button>
		</HeaderLoginBoxWrapper>
	) : (
		<LoadingDots />
	);
};

const mapStateToProps = (state: any) => ({
	currentPage: getCurrentPage(state),
	user: getUser(state),
	isUserInfoLoading: getUserFetchStatus(state),
});

const mapDispatchToProps = {
	onSignOut: signOut,
	onFetchUserDetails: fetchUserDetails,
	openModal: openLoginModal,
};

const HeaderLoginBox = withCookies(
	connect(mapStateToProps, mapDispatchToProps)(HeaderLogin),
);

export default HeaderLoginBox;
