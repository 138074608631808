import React, { forwardRef, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import Conditional from 'Components/common/conditional';

import { Chevron } from 'Assets/svg/productPage';

import { getRemValuesForStyling } from 'Utils/stylingUtils';

import colors from 'Static/typography/colors';

const AccordionWrapper = styled.section<{
	isOpen: boolean;
	isWholeAccordionClickable: boolean;
	addHeaderPadding: boolean;
	isDisabled: boolean;
	isBroadway: boolean;
	isDesktop: boolean;
	isHeadoutMembership?: boolean;
	isExperiment?: boolean;
}>`
	${({ addHeaderPadding }) =>
		addHeaderPadding ? `padding-bottom: 1.75rem;` : ``}
	${({ isDisabled }) => (isDisabled ? `pointer-events:none;` : ``)}
	${({ isWholeAccordionClickable }) =>
		isWholeAccordionClickable && `cursor: pointer`};
	${({ isBroadway, isDesktop, isExperiment }) =>
		isBroadway &&
		isDesktop &&
		!isExperiment &&
		`border-bottom: 1px solid ${colors.GREY.E2};`}
	${({ isBroadway, isDesktop, isExperiment }) => {
		let style = '';
		if (isBroadway && !isDesktop && !isExperiment) {
			style += `position:relative;&:after{content:"";position:absolute;bottom:0;width:calc(100% - 48px);background-color:#e2e2e2;height:1px;left:24px;}`;
		}
		return style;
	}}
	display: grid;
	grid-template-rows: max-content max-content;
	grid-row-gap: ${({ isOpen, isBroadway }) => {
		if (isBroadway) {
			return 0;
		} else {
			return isOpen ? '1.6rem' : 0;
		}
	}};

	${({ isHeadoutMembership }) => isHeadoutMembership && `padding: 0 1rem;`}
	${({ isHeadoutMembership, isOpen }) =>
		isHeadoutMembership &&
		isOpen &&
		`background: linear-gradient(
			to top,
		  rgba(228, 86, 255, 0.06),
			rgba(228, 86, 255, 0)
		);`}

	transition: grid-row-gap 0.05s ease !important;
	:focus {
		outline: none;
	}
`;

const HeaderWrapper = styled.div<{
	isDisabled: boolean;
	isBroadway: boolean;
	isDesktop: boolean;
	isOpen: boolean;
	isHeadoutMembership: boolean;
}>`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 0.625rem;
	cursor: pointer;
	${({ isBroadway, isDesktop }) => {
		let style = '';
		style += isBroadway && !isDesktop ? 'padding:0 1.5rem;' : '';
		return style;
	}}
	${({ isHeadoutMembership }) =>
		isHeadoutMembership && `border-top: 1px solid ${colors.GREY.E2};`}
	${({ isDisabled }) =>
		isDisabled ? `svg{path{stroke:#9F9F9F;}}h2{color:#9F9F9F}` : ``}
	:focus {
		outline: none;
	}
`;

const IconWrapper = styled.div<{
	isOpen: boolean;
	isBroadway: boolean;
	isDesktop: boolean;
	isFirst: boolean;
}>`
	display: inline-block;
	align-self: center;
	margin-left: auto;
	cursor: pointer;
	${({ isOpen }) => isOpen && 'transform: rotate(180deg);'}
	${({ isBroadway, isFirst }) => {
		let style = '';
		if (isBroadway) {
			style += `margin-top:6px;${isFirst && 'align-self:start;'}`;
		}
		return style;
	}}
	transition: transform 0.1s ease !important;
`;

const ContentBlock = styled.div<{
	isOpen: boolean;
	isBroadway: boolean;
	isDesktop: boolean;
	isFirst: boolean;
	isLast: boolean;
	skipPadding?: boolean;
}>`
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
	${({ isBroadway, isDesktop, isFirst, isLast, skipPadding }) =>
		isBroadway &&
		!skipPadding &&
		getRemValuesForStyling(
			{
				'padding-left': isDesktop ? 32 : isFirst ? 0 : isLast ? 24 : 52,
				'padding-right': isDesktop
					? isLast
						? 52
						: 0
					: isFirst
					? 0
					: 24,
				'padding-bottom': 24,
			},
			isDesktop,
		)}
	:focus {
		outline: none;
	}
`;

type ClickableRegionTypes = 'header' | 'whole';

interface IOpenHandler {
	handleSelection: Function;
	currentStep: string;
}
interface IAccordionProps {
	header: React.ReactNode;
	children: React.ReactNode;
	customIcon?: React.ReactNode;
	clickEvent?: (x: { accordionState: boolean }) => void;
	isAccordionPanelOpen?: boolean;
	ignoredElementsForClickBubbling?: Array<string>;
	clickableRegion?: ClickableRegionTypes;
	addHeaderPadding?: boolean;
	isDisabled?: boolean;
	isBroadway?: boolean;
	isDesktop?: boolean;
	openHandler?: IOpenHandler;
	isFirst?: boolean;
	isLast?: boolean;
	shouldStopEventPropogation?: boolean;
	isHeadoutMembership?: boolean;
	onOpen?: () => void;
	isExperiment?: boolean;
	skipPadding?: boolean;
}

const Accordion = forwardRef(
	(
		{
			header,
			children,
			customIcon,
			isAccordionPanelOpen,
			clickEvent,
			ignoredElementsForClickBubbling = [
				'a',
				'button',
				'input',
				'iframe',
			],
			clickableRegion = 'whole',
			addHeaderPadding = true,
			isDisabled = false,
			isBroadway = false,
			isDesktop = true,
			openHandler,
			isFirst = false,
			isLast = false,
			shouldStopEventPropogation = false,
			isHeadoutMembership = false,
			onOpen,
			isExperiment,
			skipPadding,
		}: IAccordionProps,
		ref,
	): ReactElement => {
		const [isOpen, setOpen] = useState(false || isAccordionPanelOpen);

		useEffect(() => {
			if (isAccordionPanelOpen) {
				onOpen?.();
			}
			setOpen(isAccordionPanelOpen);
		}, [isAccordionPanelOpen]);

		const handleClick = (e: any) => {
			if (!isDisabled) {
				if (
					ignoredElementsForClickBubbling.includes(e.target.localName)
				) {
					return;
				}
				setOpen(c => !c);

				if (shouldStopEventPropogation) {
					e.stopPropagation();
				}

				if (openHandler) {
					openHandler.handleSelection(
						openHandler.currentStep,
						!isOpen,
					);
				}
				// @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
				clickEvent?.({ accordionState: isOpen });
			}
		};
		return (
			// @ts-expect-error TS(2769): No overload matches this call.
			<AccordionWrapper
				isOpen={isOpen}
				isWholeAccordionClickable={clickableRegion === 'whole'}
				onClick={
					isDisabled
						? () => {}
						: clickableRegion === 'whole'
						? handleClick
						: null
				}
				addHeaderPadding={addHeaderPadding}
				isDisabled={isDisabled}
				isDesktop={isDesktop}
				isBroadway={isBroadway}
				isHeadoutMembership={isHeadoutMembership}
				ref={ref}
				isExperiment={isExperiment}
			>
				{/* @ts-expect-error TS(2769): No overload matches this call. */}
				<HeaderWrapper
					role='button'
					onClick={clickableRegion === 'header' ? handleClick : null}
					isDisabled={isDisabled}
					isBroadway={isBroadway}
					isDesktop={isDesktop}
					isOpen={isOpen}
					isHeadoutMembership={isHeadoutMembership}
				>
					{header}

					<Conditional
						if={(isBroadway && !isDisabled) || !isBroadway}
					>
						<IconWrapper
							// @ts-expect-error TS(2769): No overload matches this call.
							isOpen={isOpen}
							isBroadway={isBroadway}
							isDesktop={isDesktop}
							isFirst={isFirst}
						>
							{customIcon ? customIcon : <Chevron />}
						</IconWrapper>
					</Conditional>
				</HeaderWrapper>

				<ContentBlock
					// @ts-expect-error TS(2769): No overload matches this call.
					isOpen={isOpen}
					isBroadway={isBroadway}
					isDesktop={isDesktop}
					isLast={isLast}
					isFirst={isFirst}
					skipPadding={skipPadding}
				>
					{children}
				</ContentBlock>
			</AccordionWrapper>
		);
	},
);

Accordion.displayName = 'Accordion';

export default Accordion;
