import React from 'react';
import styled from 'styled-components';

import { HOProText } from 'Assets/svg/headoutProText';
import { HOProUserImageBackground } from 'Assets/svg/headoutProUserImageBackground';

const HeadoutProTagWrapper = styled.div`
	position: absolute;
	bottom: -2px;

	.relative-container {
		position: relative;
	}
`;

const HeadoutProTextWrapper = styled.div`
	position: absolute;
	top: calc(50% + 2px);
	left: 50%;
	transform: translate(-50%, calc(-50% - 1px));
`;

interface IHeadoutProImageTag {
	isMobileProfilePage?: boolean;
}

export const HeadoutProImageTag: React.FC<
	React.PropsWithChildren<IHeadoutProImageTag>
> = ({ isMobileProfilePage = false }) => {
	const bgHeight = 25;
	const bgWidth = isMobileProfilePage ? 65 : 50;

	const textHeight = isMobileProfilePage ? 15 : 7;
	const textWidth = isMobileProfilePage ? 25 : 18;

	return (
		<HeadoutProTagWrapper>
			<div className='relative-container'>
				<HOProUserImageBackground height={bgHeight} width={bgWidth} />
				<HeadoutProTextWrapper>
					<HOProText width={textWidth} height={textHeight} />
				</HeadoutProTextWrapper>
			</div>
		</HeadoutProTagWrapper>
	);
};
