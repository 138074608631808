import React from 'react';
import styled from 'styled-components';

import { IMembershipDetails, MembershipStageProgressBar } from '@headout/aqua';

import { useMembershipProfileViewed } from 'Hooks/useMembershipProfileViewed';

import colors from 'Static/typography/colors';

import Accordion from './accordion';
import Conditional from './conditional';

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 1rem;

	.account-item {
		padding: 0.375rem 0;
		cursor: pointer;
		display: flex;
		align-items: center;
		text-decoration: none;

		color: ${colors.CHARCOAL};
		text-align: left;

		span {
			font-size: 0.875rem;
		}

		&:hover {
			.label {
				color: ${colors.PURPS};
			}
		}
	}

	.red-dot {
		background-color: ${colors.WARNING_RED};
		height: 0.375rem;
		width: 0.375rem;
		border-radius: 50%;
		margin-left: 0.5rem;
	}
`;

const MembershipStatusBarWrapper = styled.div`
	margin-bottom: 1.25rem;
`;

interface IMembershipLoginItemHeading {
	membershipDetails: IMembershipDetails;
}

const MembershipLoginItemHeading: React.FC<
	React.PropsWithChildren<IMembershipLoginItemHeading>
> = props => {
	const { membershipDetails } = props;
	const { isProfileViewed, handleProfileViewed } =
		useMembershipProfileViewed();

	return (
		<Accordion
			header={
				<HeadingContainer>
					<span className='account-item'>Headout PRO</span>
					<Conditional if={!isProfileViewed}>
						<span className='red-dot' />
					</Conditional>
				</HeadingContainer>
			}
			clickableRegion='header'
			shouldStopEventPropogation
			clickEvent={handleProfileViewed}
			addHeaderPadding={false}
			isHeadoutMembership={true}
		>
			<MembershipStatusBarWrapper>
				<MembershipStageProgressBar
					membershipStage={membershipDetails!.stage}
					membershipBenefits={membershipDetails!.benefits}
					onExperienceNameClick={null}
				/>
			</MembershipStatusBarWrapper>
		</Accordion>
	);
};

export { MembershipLoginItemHeading };
