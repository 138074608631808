import React from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import Router, { withRouter } from 'next/router';
import styled from 'styled-components';

import { IMembershipDetails } from '@headout/aqua';

import enhanceWithClickOutside from 'Components/common/outsideClickHoc';

import { trackEvent } from 'Utils/analytics';
import { convertibleFormatPrice, truncateWithEllipsis } from 'Utils/gen';
import { isWhitelabel as isWhitelabelledHost } from 'Utils/hostUtils';
import { getRandomImage } from 'Utils/reviewUtils';
import {
	getDomainConfig,
	getHost,
	getMembershipDetailsFromReduxStore,
} from 'Utils/stateUtils';

import { ANALYTICS_EVENTS } from 'Constants/analytics';
import {
	PAGE_TYPE,
	PROFILE_PAGE_TAB,
	UserProfileTypes,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';

import LSpan from './localizedTags/localizedSpan';
import Conditional from './conditional';
import { HeadoutMembershipRedDot } from './headoutMembershipRedDot';
import { HeadoutProImageTag } from './headoutProImgTag';
import LinkWrapper from './linkWrapper';
import { MembershipLoginItemHeading } from './membershipLoginItemHeading';

const HeaderUserBoxWrapper = styled.div<{ isMembershipUser?: boolean }>`
	position: relative;
	margin-left: 12px;
	display: flex;
	align-items: center;
	border-width: 1px;
	border-style: solid;
	border-radius: 50%;

	${({ isMembershipUser }) =>
		isMembershipUser
			? `border-width: 1.5px; border-color: rgba(241, 76, 255, 1);}`
			: `border-color: ${colors.WHITE}`};

	.user-info-wrapper {
		height: 100%;
		cursor: pointer;
		display: flex;
		align-items: center;

		.user-image {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;
			position: relative;

			svg,
			img {
				user-drag: none;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.user-popup {
		position: absolute;
		width: 18.75rem;
		transform: scaleY(0);
		transform-origin: top;
		top: 3.75rem;
		right: 0;
		box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1),
			0px 2px 8px rgba(0, 0, 0, 0.1);
		border-radius: 0.5rem;
		transition: all 0.2s ease-in-out 0s;
		max-height: 0;
		overflow: hidden;
		z-index: 300;
		background-color: white;
		opacity: 0;
		pointer-events: none;

		&.hovered {
			z-index: 9999;
			top: 3.75rem;
			max-height: 37.5rem;
			transform: scaleY(1);
			opacity: 1;
			pointer-events: auto;
		}

		.divider {
			margin: 0 1.25rem;
			border: 0;
			border-bottom: 1px ${colors.GREY.E7} solid;
		}

		.user-details-wrapper {
			margin: 1.25rem;
			${({ isMembershipUser }) =>
				isMembershipUser && 'margin-bottom: 1rem;'};
			display: flex;

			.user-image {
				width: 3.125rem;
				height: 3.125rem;
				flex-shrink: 0;
				overflow: hidden;
				border-radius: 50%;
				position: relative;

				${({ isMembershipUser }) =>
					isMembershipUser &&
					`	border-width: 1.5px; 
						border-style: solid;
					 	border-color: rgba(241, 76, 255, 1);}
					`};

				svg,
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.user-name-email-wrapper {
				display: flex;
				flex-direction: column;
				flex: 1;
				margin-left: 0.75rem;

				.user-name {
					width: 100%;
					margin-bottom: 0.375rem;
					span {
						font-weight: 500;
					}
				}

				.user-email {
					width: 100%;

					span {
						color: ${colors.GREY['9A']};
					}
				}
			}

			.user-image-large {
				cursor: pointer;
			}
		}

		.mt0 {
			margin-top: 0 !important;
		}

		.account-items {
			margin: 0.625rem 1.25rem;

			.account-item {
				padding: 0.625rem 0;
				cursor: pointer;
				display: flex;
				align-items: center;
				text-decoration: none;

				&:hover {
					.label {
						color: ${colors.PURPS};
					}
				}

				.label {
					flex: 1;
					color: ${colors.CHARCOAL};
					text-align: left;

					span {
						font-size: 0.875rem;
					}
				}

				&.wallet-wrapper {
					cursor: auto;

					&:hover {
						.label {
							color: ${colors.PURPS};
						}
					}

					.wallet-amount {
						color: ${colors.PURPS};

						span {
							font-size: 0.875rem;
							font-weight: 500;
						}
					}

					a {
						display: contents;
					}
				}

				&.sign-out-wrapper {
					.label {
						color: ${colors.GREY['9A']};
						transition: all 0.2s ease-in-out 0s;
					}

					&:hover {
						.label {
							color: ${colors.PURPS};
						}
					}
				}
			}
		}

		.extra-items-list {
			margin: 0 20px 25px;
		}
	}
`;

const CreditsWrapper = styled.div<{ $isWhitelabel: boolean }>`
	&:hover {
		// important is required as classnames are taking precedence over styled components
		.label {
			color: ${({ $isWhitelabel }) =>
				$isWhitelabel ? colors.CHARCOAL : colors.PURPS} !important;
		}
	}
`;

type HeaderUserBoxProps = {
	user?: any;
	onSignOut?: (...args: any[]) => any;
	currentPage?: string;
	host?: string;
	loginConfig?: any;
	checkoutFlow?: boolean;
	membershipDetails?: IMembershipDetails;
	router: any;
};

type HeaderUserBoxState = any;

class HeaderUserBox extends React.Component<
	HeaderUserBoxProps,
	HeaderUserBoxState
> {
	state = {
		showUserInfo: false,
	};

	onSignOut = () => {
		const { currentPage, onSignOut } = this.props;
		if (
			currentPage === PAGE_TYPE.ACCOUNT ||
			currentPage === PAGE_TYPE.ORDER_STATUS
		) {
			this.setState({ showHover: true });
			Router.replace('/');
		}
		onSignOut?.();
	};

	handleUserInfoDropdown = () => {
		this.setState({ showUserInfo: !this.state.showUserInfo });
		trackEvent({
			eventName: ANALYTICS_EVENTS.USER_PROFILE.ICON_CLICKED,
		});
	};

	handleClickOutside = () => {
		this.setState({ showUserInfo: false });
	};

	getUserImage = () => {
		const {
			user: { name, imageUrl },
			membershipDetails,
		} = this.props;

		const isMembershipUser =
			membershipDetails && membershipDetails?.isActivated;

		return (
			<div className='user-image'>
				{/*eslint-disable-next-line @next/next/no-img-element*/}
				<img alt={name} src={imageUrl ?? getRandomImage(name)} />
				<Conditional if={isMembershipUser}>
					<HeadoutProImageTag />
				</Conditional>
			</div>
		);
	};

	getUserCreditsSection = () => {
		const formattedWalletAmount = convertibleFormatPrice(this.props.user);

		return (
			<>
				<div className='label'>
					<LSpan>{strings.UPP_TABS.CREDITS}</LSpan>
				</div>
				<div className='wallet-amount'>
					<span>{formattedWalletAmount}</span>
				</div>
			</>
		);
	};

	render() {
		const {
			user,
			loginConfig: { showWrapper },
			router: {
				query: { lang },
			},
			membershipDetails,
			host,
		} = this.props;
		const { name, email, userProfileType, affiliateDetails } = user;

		const { markUpEnabled } = affiliateDetails ?? {};

		const langURLPrefix = lang && lang !== 'en' ? `/${lang}` : '';
		const isMembershipUser =
			membershipDetails && membershipDetails?.isActivated;
		const isWhitelabel =
			isWhitelabelledHost({ host }) || (host ?? '').startsWith('book.');

		return (
			<HeaderUserBoxWrapper
				className='header-user-box-wrapper'
				isMembershipUser={isMembershipUser}
			>
				<Conditional if={showWrapper}>
					<div
						className='header-user-wrapper'
						onClick={this.handleUserInfoDropdown}
					>
						<div className='user-info-wrapper'>
							{this.getUserImage()}
						</div>
						<div
							className={`user-popup ${
								this.state.showUserInfo ? 'hovered' : ''
							}`}
						>
							<div className='user-details-wrapper'>
								<div className='user-image-large'>
									{this.getUserImage()}
								</div>
								<div className='user-name-email-wrapper'>
									<div className='user-name'>
										<span>{name}</span>
									</div>
									<div className='user-email'>
										<span>
											{truncateWithEllipsis({
												text: email,
												limit: 21,
											})}
										</span>
									</div>
								</div>
							</div>
							<Conditional if={isMembershipUser}>
								<MembershipLoginItemHeading
									membershipDetails={membershipDetails!}
								/>
							</Conditional>
							<div
								className={`account-items ${
									isMembershipUser ? 'mt0' : ''
								}`}
							>
								<Conditional
									if={
										markUpEnabled &&
										userProfileType ===
											UserProfileTypes.DISTRIBUTION_PARTNER
									}
								>
									<Link
										href={`${langURLPrefix}/profile/${PROFILE_PAGE_TAB.PRICE_MARKUP}/`}
										prefetch={false}
										className='item-link account-item'
										rel='nofollow'
									>
										<div className='label'>
											<LSpan>
												{strings.UPP_TABS.PRICE_MARKUP}
											</LSpan>
										</div>
									</Link>
								</Conditional>
								<Conditional if={!isWhitelabel}>
									<Link
										href={`${langURLPrefix}/profile/${PROFILE_PAGE_TAB.BOOKING}`}
										prefetch={false}
										className='item-link account-item'
										rel='nofollow'
									>
										<div className='label'>
											<LSpan>
												{strings.UPP_TABS.RESERVATIONS}
											</LSpan>
										</div>
									</Link>
								</Conditional>

								<Conditional
									if={
										userProfileType !==
										UserProfileTypes.DISTRIBUTION_PARTNER
									}
								>
									<CreditsWrapper
										$isWhitelabel={isWhitelabel}
										className='account-item wallet-wrapper'
									>
										{isWhitelabel ? (
											<>{this.getUserCreditsSection()}</>
										) : (
											<LinkWrapper
												href={`${langURLPrefix}/profile/${PROFILE_PAGE_TAB.CREDITS}`}
												url={`${langURLPrefix}/profile/${PROFILE_PAGE_TAB.CREDITS}`}
												className={'item-link'}
												linkProps={{ rel: 'nofollow' }}
											>
												{this.getUserCreditsSection()}
											</LinkWrapper>
										)}
									</CreditsWrapper>
								</Conditional>
								<div
									className='account-item sign-out-wrapper'
									onClick={this.onSignOut}
								>
									<div className='label'>
										<LSpan>
											{strings.UPP_TABS.SIGN_OUT}
										</LSpan>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Conditional>
				<Conditional if={isMembershipUser}>
					<HeadoutMembershipRedDot />
				</Conditional>
			</HeaderUserBoxWrapper>
		);
	}
}

const mapStateToProps = (state: any) => ({
	host: getHost(state),
	loginConfig: getDomainConfig(state)?.['login'] || {
		showGoogleLoginButton: true,
		showWrapper: true,
		showAppleLoginButton: true,
	},
	membershipDetails: getMembershipDetailsFromReduxStore(state) || null,
});

export default withRouter(
	connect(mapStateToProps, null)(enhanceWithClickOutside(HeaderUserBox)),
);
