import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	getPostActivationNudgeStatusObject,
	setPostActivationStatusInAsyncStorage,
} from '@headout/aqua';

import { setMembershipProfileViewed } from 'Actions/membership';

export const useMembershipProfileViewed = () => {
	const { isProfileViewed } = useSelector(state => {
		return {
			isProfileViewed:
				//@ts-expect-error
				state?.membershipData?.isMembershipProfileViewed ?? false,
		};
	});

	const dispatch = useDispatch();

	const handleProfileViewed = () => {
		dispatch(setMembershipProfileViewed(true));
		setPostActivationStatusInAsyncStorage({
			statusType: 'profileSeenByUser',
			statusValue: true,
		});
	};

	useEffect(() => {
		(async () => {
			const postActivationNudgeStatus =
				await getPostActivationNudgeStatusObject();

			if (postActivationNudgeStatus) {
				const { profileSeenByUser } = postActivationNudgeStatus;
				if (profileSeenByUser) {
					dispatch(
						setMembershipProfileViewed(Boolean(profileSeenByUser)),
					);
				}
			} else {
				dispatch(setMembershipProfileViewed(false));
				setPostActivationStatusInAsyncStorage({
					statusType: 'profileSeenByUser',
					statusValue: false,
				});
			}
		})();
	}, [dispatch]);

	return { isProfileViewed: isProfileViewed, handleProfileViewed };
};
