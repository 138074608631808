import React from 'react';
import { findDOMNode } from 'react-dom';
import hoistNonReactStatic from 'hoist-non-react-statics';

/* eslint no-underscore-dangle: 0 */
const enhanceWithClickOutside = function enhanceWithClickOutside(
	WrappedComponent: any,
) {
	const componentName = WrappedComponent.displayName || WrappedComponent.name;

	class EnhancedComponent extends React.Component {
		constructor(props: any) {
			super(props);
			this.handleClickOutside = this.handleClickOutside.bind(this);
		}

		componentDidMount() {
			document.addEventListener('click', this.handleClickOutside, true);
		}

		componentWillUnmount() {
			document.removeEventListener(
				'click',
				this.handleClickOutside,
				true,
			);
		}
		isTypeFunction = (t: any) => typeof t === 'function';

		handleClickOutside(e: any) {
			const domNode = (this as any).__domNode;
			const isWrappedComponentVisible = domNode?.clientHeight > 0;
			if (
				(!domNode ||
					(this.isTypeFunction(domNode.contains) &&
						!domNode.contains(e.target))) &&
				this.isTypeFunction(
					(this as any).__wrappedComponent.handleClickOutside,
				) &&
				isWrappedComponentVisible
			) {
				(this as any).__wrappedComponent.handleClickOutside(e);
			}
		}

		render() {
			return (
				<WrappedComponent
					{...this.props}
					ref={(c: any) => {
						(this as any).__wrappedComponent = c;
						// eslint-disable-next-line react/no-find-dom-node
						(this as any).__domNode = findDOMNode(c);
					}}
				/>
			);
		}
	}

	(EnhancedComponent as any).displayName = `Wrapped${componentName}`;

	return hoistNonReactStatic(EnhancedComponent, WrappedComponent);
};

export default enhanceWithClickOutside;
