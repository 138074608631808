import * as React from 'react';

export const HOProText = (props: any) => (
	<svg
		width={18}
		height={7}
		viewBox='0 0 18 7'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M0 6.37984V0.132202H2.30533C4.09215 0.132202 4.91313 0.790517 4.91313 2.16561C4.91313 3.47461 4.02861 4.27272 2.21129 4.27272H1.51486V6.37984H0ZM1.51486 3.25602H2.18333C3.11614 3.23823 3.44402 2.86967 3.44402 2.16561C3.44402 1.46917 3.08564 1.17686 2.1935 1.17686H1.51486V3.25602Z'
			fill='white'
		/>
		<path
			d='M5.66895 6.3785V0.130859H8.1827C10.0941 0.130859 10.732 0.845093 10.732 1.844C10.732 2.7387 10.1576 3.33093 9.22734 3.52919C10.2161 3.76557 10.6482 4.4976 10.8184 5.89048C10.8362 6.00232 10.8667 6.30478 10.8744 6.38104H9.31122C9.31122 6.38104 9.30105 6.16499 9.29342 6.08874C9.16125 4.87378 8.77491 4.12142 7.72265 4.11125H7.15839V6.3785H5.67149H5.66895ZM7.15585 3.19876L7.94632 3.16063C8.86896 3.16063 9.2553 2.62432 9.2553 2.07022C9.2553 1.48562 8.90708 1.17552 8.02257 1.17552H7.15585V3.19876Z'
			fill='white'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11.6304 3.25599C11.6304 1.31663 12.9089 0 14.7821 0C16.7011 0 17.9999 1.31663 17.9999 3.25599C17.9999 5.19535 16.7672 6.51198 14.8482 6.51198C12.9292 6.51198 11.6304 5.19535 11.6304 3.25599ZM15.9282 4.06043L16.888 3.67232L16.8903 3.67464C17.1645 3.56077 17.1669 3.17033 16.8903 3.05645L15.9003 2.64974C15.7353 2.58234 15.6051 2.4522 15.5377 2.28719L15.1264 1.29018C15.0125 1.01594 14.6244 1.01594 14.5105 1.29018L14.0992 2.28719C14.0318 2.4522 13.9017 2.58234 13.7367 2.64974L12.7397 3.0611C12.4654 3.17497 12.4654 3.56309 12.7397 3.67697L13.7367 4.08832C13.9017 4.15572 14.0318 4.28586 14.0992 4.45087L14.5129 5.45486C14.6267 5.72677 15.0148 5.72909 15.1287 5.45486L15.5633 4.42066C15.633 4.25565 15.7632 4.12783 15.9282 4.06043Z'
			fill='white'
		/>
	</svg>
);
